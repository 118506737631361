<h1 mat-dialog-title>
  <mat-icon
    class="dialog-header-icon"
    svgIcon="gitlab"
  ></mat-icon>
  <span>{{T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.TITLE|translate}}</span>
</h1>

<mat-dialog-content>
  <div
    *ngIf="isLoading"
    class="loading-wrapper"
  >
    <mat-progress-spinner
      class="example-margin"
      [color]="'primary'"
      [mode]="'indeterminate'"
    >
    </mat-progress-spinner>
  </div>

  <h3>For gitlab project: <strong>{{(issueProviderCfg$|async)?.project}}</strong></h3>

  <div class="table-wrapper">
    <table
      [dataSource]="tmpTasks$|async"
      class="summary-table"
      mat-table
    >
      <ng-container matColumnDef="title">
        <th
          *matHeaderCellDef
          mat-header-cell
        >
          {{T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_TITLE|translate}} Title
        </th>
        <td
          *matCellDef="let tmpTask"
          mat-cell
        >
          <div class="task-title">{{tmpTask.title}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="timeTrackedAlreadyRemote">
        <th
          *matHeaderCellDef
          mat-header-cell
        >
          {{T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_ALREADY_TRACKED|translate}}
        </th>
        <td
          *matCellDef="let tmpTask"
          mat-cell
        >
          {{tmpTask.timeTrackedAlreadyRemote|msToClockString}}
        </td>
      </ng-container>

      <ng-container matColumnDef="timeToSubmit">
        <th
          *matHeaderCellDef
          mat-header-cell
        >
          {{T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_TO_BE_SUBMITTED|translate}}
        </th>
        <td
          *matCellDef="let tmpTask"
          mat-cell
        >
          <span
            class="past-info-ico"
            *ngIf="tmpTask.isPastTrackedData"
            [matTooltip]="T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.PAST_DAY_INFO|translate"
            >!!!</span
          >
          <inline-input
            (changed)="updateTimeSpentTodayForTask(tmpTask, $event)"
            [displayValue]="tmpTask.timeToSubmit|msToClockString"
            [type]="'duration'"
            [value]="tmpTask.timeToSubmit"
          >
          </inline-input>
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="['title', 'timeTrackedAlreadyRemote','timeToSubmit']"
        mat-header-row
      ></tr>
      <tr
        *matRowDef="let row; columns: ['title','timeTrackedAlreadyRemote','timeToSubmit']"
        mat-row
      ></tr>
    </table>
  </div>

  <div
    class="total-msg"
    [innerHTML]="T.F.GITLAB.DIALOG_SUBMIT_WORKLOG.TOTAL_MSG|translate:{totalTimeToSubmit: totalTimeToSubmit$|async|msToString,
    nrOfTasksToSubmit: (tmpTasksToTrack$|async)?.length}"
  ></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    (click)="close()"
    color="primary"
    mat-button
    type="button"
  >
    {{T.G.CANCEL|translate}}
  </button>

  <button
    color="primary"
    mat-stroked-button
    type="button"
    (click)="submit()"
  >
    <mat-icon
      class="dialog-header-icon"
      svgIcon="gitlab"
    ></mat-icon>
    {{T.G.SUBMIT|translate}}
  </button>
</mat-dialog-actions>
