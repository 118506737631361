<h4>Connect Calendar</h4>
<div class="items">
  <button
    mat-raised-button
    (click)="openSetupDialog('ICAL', 'GOOGLE')"
  >
    <mat-icon>calendar_month</mat-icon>
    <span>Google Calendar</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('ICAL', 'OUTLOOK365')"
  >
    <mat-icon>calendar_month</mat-icon>
    <span>Outlook 365</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('ICAL', 'OTHER')"
  >
    <mat-icon>calendar_month</mat-icon>
    <span>iCal Other</span>
  </button>
</div>

<h4>Setup Issue Provider</h4>

<div class="items">
  <button
    mat-raised-button
    (click)="openSetupDialog('JIRA')"
  >
    <mat-icon svgIcon="jira"></mat-icon>
    <span>Jira</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITHUB')"
  >
    <mat-icon svgIcon="github"></mat-icon>
    <span>GitHub</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('REDMINE')"
  >
    <mat-icon svgIcon="redmine"></mat-icon>
    <span>Redmine</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITLAB')"
  >
    <mat-icon svgIcon="gitlab"></mat-icon>
    <span>GitLab</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('CALDAV')"
  >
    <mat-icon svgIcon="caldav"></mat-icon>
    <span>CalDAV</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('OPEN_PROJECT')"
  >
    <mat-icon svgIcon="open_project"></mat-icon>
    <span>Open Project</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITEA')"
  >
    <mat-icon svgIcon="gitea"></mat-icon>
    <span>Gitea</span>
  </button>
</div>

@if(issueProvidersMapped().length > 1) {
<h4>Sort Order</h4>
<div
  class="provider-items"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
>
  @for (entry of issueProvidersMapped(); track entry.issueProvider.id) {

  <div
    class="tab-header-item provider-item"
    cdkDrag
  >
    <mat-icon [svgIcon]="entry.issueProvider.issueProviderKey|issueIcon"></mat-icon>
    <div class="initials">{{ entry.initials }}</div>
  </div>
  }
</div>

}
