<better-drawer-container
  (wasClosed)="close()"
  [isOpen]="isOpen$|async"
  [isOver]="isAlwaysOver || (layoutService.isRightPanelOver$|async)"
  [sideWidth]="40"
>
  <ng-container better-drawer-content>
    <ng-content></ng-content>
  </ng-container>

  <ng-container better-drawer-side>
    @let panelContent = panelContent$ |async;
    <div class="right-panel">
      <issue-panel
        *ngIf="panelContent === 'ADD_TASK_PANEL'"
        [@slideInFromRight]
      ></issue-panel>
      <notes
        *ngIf="panelContent === 'NOTES'"
        [@slideInFromRight]
      ></notes>
      <task-detail-panel
        [isOver]="isAlwaysOver || (layoutService.isRightPanelOver$|async)"
        [@taskDetailPanelTaskChange]=" selectedTaskWithDelayForNone?.id"
        [@.disabled]="isDisableTaskPanelAni"
        *ngIf="selectedTaskWithDelayForNone$|async as selectedTaskWithDelayForNone"
        [task]="selectedTaskWithDelayForNone"
      ></task-detail-panel>
    </div>
  </ng-container>
</better-drawer-container>
