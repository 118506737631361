<div class="page-wrapper">
  <div class="component-wrapper">
    <h1
      class="mat-h1"
      style="display: flex; align-items: center"
    >
      <mat-icon style="margin-right: 8px">folder_special</mat-icon>
      <span>{{T.PS.PROJECT_SETTINGS|translate}}</span>
    </h1>
    <section class="config-section">
      <config-section
        (save)="saveBasicSettings($event)"
        [cfg]="currentProject"
        [section]="basicFormCfg"
      ></config-section>
    </section>

    <section class="config-section">
      <config-section
        (save)="saveProjectThemCfg($event)"
        [cfg]="currentProjectTheme"
        [section]="projectThemeSettingsFormCfg"
      ></config-section>
    </section>
  </div>
</div>
