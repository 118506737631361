<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Your Inbox</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>The inbox is responsible for two things:</p>

    <ol>
      <li>Collect Unplanned Tasks.</li>
      <li>
        Access and manage tasks from third-party integrations, such as Jira, GitHub, and
        GitLab.
      </li>
    </ol>
    <p>To configure an issue provider click the little plus icon in the tabs below.</p>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button>Hide this info</button>
  </mat-card-actions>
</mat-card>
