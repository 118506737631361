<h1 mat-dialog-title>
  <span *ngIf="projectData.id">{{T.F.PROJECT.D_CREATE.EDIT|translate}}</span>
  <span *ngIf="!projectData.id">{{T.F.PROJECT.D_CREATE.CREATE|translate}}</span>
</h1>

<form
  (ngSubmit)="submit()"
  [formGroup]="formTheme"
>
  <mat-dialog-content>
    <formly-form
      (modelChange)="projectData = $event"
      [fields]="basicSettingsFormCfg"
      [form]="formBasic"
      [model]="projectData"
      [options]="formOptionsBasic"
    >
    </formly-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="wrap-buttons">
      <button
        (click)="cancelEdit()"
        class="btn btn-primary submit-button"
        color="primary"
        mat-button
        type="button"
      >
        {{T.G.CANCEL|translate}}
      </button>
      <button
        [disabled]="!formBasic.valid"
        class="btn btn-primary submit-button"
        color="primary"
        mat-stroked-button
        type="submit"
      >
        {{T.G.SAVE|translate}}
      </button>
    </div>
  </mat-dialog-actions>
</form>
